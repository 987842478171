import { LoadingStoreContext } from '@/core/utils/LoadingStoreContext';
import { useLoadingStore } from '@multivac-se/muwo-frontend-shared';
import { computed, Ref } from 'vue';

export function usePlaceholder(
  loadingStoreContext: LoadingStoreContext,
  hasNoData?: Ref<boolean>,
) {
  const isLoading = computed(
    () => useLoadingStore().withContext(loadingStoreContext).isLoading().value,
  );

  const showPlaceholder = computed(() => {
    return !!(isLoading.value || hasNoData?.value);
  });

  const placeholderTextKey = computed(() =>
    isLoading.value ? 'WB_LOADING_S40_Z1' : 'WB_NO_DATA_S40_Z1',
  );

  return {
    showPlaceholder,
    placeholderTextKey,
  };
}
