import {
  initLoggerByProcessEnv,
  InstanceIdsCore,
  registerAuth,
  registerAxios,
  registerI18n,
  registerRouter,
} from '@multivac-se/muwo-frontend-shared';
import { App } from 'vue';
import { createToastService } from '@multivac-se/s2-components';
import { allRoutes } from '@/core/router/routes';
import { ServiceContainer } from 'ioc-service-container';
import { supportedLanguageCodes } from '@/core/i18n/i18n.utils';
import { registerVNC } from '@/domain/vnc/registerVNC';

export async function setupServices(app: App) {
  initLoggerByProcessEnv(app);
  await registerAuth();
  ServiceContainer.set(InstanceIdsCore.toast, createToastService);
  registerRouter(allRoutes);
  await registerI18n(supportedLanguageCodes);
  registerAxios();
  setupApplicationModules();
}

function setupApplicationModules() {
  registerVNC();
}
