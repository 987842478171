import { ServiceContainer } from 'ioc-service-container';
import { isDemo } from '@multivac-se/s2-components';
import { VNCApiWebsocket } from '@/domain/vnc/api/VNCApiWebsocket';
import { VNCService } from '@/domain/vnc/service/VNCService';

export const InstanceIdVNC = {
  api: 'VNCApi',
  service: 'VNCService',
} as const;

export function registerVNC() {
  if (!isDemo()) {
    ServiceContainer.set(InstanceIdVNC.api, () => new VNCApiWebsocket());
    ServiceContainer.set(InstanceIdVNC.service, VNCService);
  }
}
