import { RouteRecordRaw } from 'vue-router';
import MachinesView from '@/domain/machines/ui/MachinesView.vue';
import { logoutHandler, RouteName } from '@multivac-se/muwo-frontend-shared';

export const RscRouteNames = {
  Machines: 'Machines',
  VNC: 'VNC',
  MachinesDemo: 'DemoMachines',
  VNCDemo: 'DemoVNC',
  Version: 'Version',
};

export const machinesRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: RscRouteNames.Machines,
    component: MachinesView,
  },
  {
    path: '/demo',
    name: RscRouteNames.MachinesDemo,
    component: MachinesView,
  },
];

export const vncRoutes: RouteRecordRaw[] = [
  {
    path: '/machines/:machineName',
    name: RscRouteNames.VNC,
    component: () => import('../../domain/vnc/ui/VNCView.vue'),
  },
  {
    path: '/demo/machines/:machineName',
    name: RscRouteNames.VNCDemo,
    component: () => import('../../domain/vnc/ui/VNCView.vue'),
  },
];

const versionRoute: RouteRecordRaw = {
  path: '/version',
  name: RouteName.VERSION,
  component: () => import('../ui/views/VersionView.vue'),
};

const authRoutes: RouteRecordRaw[] = [
  {
    path: '/login/oauth2/code',
    name: RouteName.LOGIN,
    redirect: '/',
  },
  {
    name: RouteName.LOGOUT,
    path: '/logout',
    beforeEnter: async () => {
      localStorage.clear();
      await logoutHandler();
    },
    component: { template: 'Logout' },
  },
];

export const allRoutes: RouteRecordRaw[] = [
  ...authRoutes,
  {
    path: '/demo/:pathMatch(.*)*',
    redirect: '/demo',
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
  ...machinesRoutes,
  ...vncRoutes,
  versionRoute,
];
