import '@multivac-se/s2-components/src/scss/global.scss';
import { createApp } from 'vue';
import App from '@/core/ui/App.vue';
import {
  checkBrowserCompatibility,
  createLogger,
  setupDocument,
} from '@multivac-se/muwo-frontend-shared';
import { setupServices } from '@/core/setupServices';
import { setupApp } from '@/core/setupApp';

try {
  checkBrowserCompatibility();
  setupDocument();
  const app = createApp(App);
  await setupServices(app);
  await setupApp(app);
} catch (error) {
  createLogger('main').error('An error occurred during setup. ', error);
}
