<script lang="ts" setup>
import { useRoute } from 'vue-router';
import { scg } from 'ioc-service-container';
import MachinesWidget from '@/domain/machines/ui/MachinesWidget.vue';
import { RscRouteNames } from '@/core/router/routes';
import { InstanceIdsCore } from '@multivac-se/muwo-frontend-shared';

const route = useRoute();
const router = scg(InstanceIdsCore.router);
const machineName = route.query['machine-serial-no'];

if (machineName) {
  router.push({
    name: RscRouteNames.VNC,
    params: {
      machineName: String(machineName),
    },
  });
}
</script>

<template>
  <div>
    <MachinesWidget />
  </div>
</template>
