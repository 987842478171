<script lang="ts" setup>
import {
  isDemo,
  S2cToastContainer,
  S2cLoadingIndicator,
  S2cBanner,
  ToastService,
  useMachineStore,
} from '@multivac-se/s2-components';
import {
  createLogger,
  InstanceIdsCore,
  useLoadingStore,
} from '@multivac-se/muwo-frontend-shared';
import { ref, onMounted, computed } from 'vue';
import { useSwcHeaderFooterUtils } from '@/core/swcHeaderFooter/swcHeaderFooterUtils';
import { scg } from 'ioc-service-container';
import { LoadingStoreContext } from '@/core/utils/LoadingStoreContext';
import { useI18n } from 'vue-i18n';

const logger = createLogger('App');
const { authorizationHeader, appId, appTitle } = useSwcHeaderFooterUtils();
const toastContainerRef = ref(null);
const { t } = useI18n();

const isLoadingVNC = computed(
  () =>
    useLoadingStore().withContext(LoadingStoreContext.vnc).isLoading().value,
);

const { setMachines, fetchMachines } = useMachineStore();
const loadingStore = useLoadingStore().withContext(
  LoadingStoreContext.machines,
);

function resetMachineStore() {
  setMachines([]);
}

async function refreshMachines() {
  try {
    await loadingStore.loadOrThrow(async () => {
      await fetchMachines();
    });
  } catch (error: any) {
    logger.error('Error fetching machines', error);
    resetMachineStore();
    scg<ToastService>(InstanceIdsCore.toast).showError(
      t('WB_AN_ERROR_OCCURRED_S40_Z1'),
      'Machines',
    );
  }
}

function registerServices() {
  scg(InstanceIdsCore.toast).registerContainer(toastContainerRef.value!);
}

onMounted(() => {
  registerServices();
  refreshMachines();
});
</script>

<template>
  <div class="s4-layout-container">
    <div>
      <swc-header
        ref="swc-header"
        :authorization-header="authorizationHeader"
        class="layout-container-header"
        login-url="/login"
        logout-url="/logout"
        :smart-service-id="appId"
        :app-name="appTitle"
      />
      <S2cLoadingIndicator :loading="isLoadingVNC" />
      <s2c-banner
        v-if="isDemo()"
        content="Demo"
      />
    </div>
    <router-view class="layout-container-main-content" />
  </div>
  <swc-footer ref="swc-footer" />
  <S2cToastContainer
    ref="toastContainerRef"
    is-filtering-duplicates
  />
</template>
