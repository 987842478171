import { readSharedEnvValueOrThrow } from '@multivac-se/muwo-frontend-shared';
import { ref } from 'vue';

const appId: string = readSharedEnvValueOrThrow('APP_ID');
const appTitle: string = readSharedEnvValueOrThrow('APP_TITLE');
const appMode: string = readSharedEnvValueOrThrow('APP_MODE').toUpperCase();

const authorizationHeader = ref<string | null>(null);

export function useSwcHeaderFooterUtils() {
  return {
    appId,
    appMode,
    appTitle,
    authorizationHeader,
  };
}
