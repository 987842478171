import { VNCApi } from '@/domain/vnc/api/VNCApi';
import VNC from '@novnc/novnc/core/rfb';
import { VNCConnection } from '@/domain/vnc/models/VNCConnection';

export class VNCApiWebsocket implements VNCApi {
  private wsBaseUrl = process.env.VUE_APP_WS_URL;

  constructor() {
    if (!this.wsBaseUrl) {
      throw new Error('VUE_APP_WS_URL is not defined');
    }
  }

  createAndAttachVNCConnection(
    screen: HTMLElement,
    machineName: string,
  ): VNCConnection {
    const ws = new WebSocket(
      `${this.wsBaseUrl}/websockify?machineName=${machineName}`,
    );

    // when noVNC-Client is initialized, screen.appendChild() is called to attach a canvas HTML-Element to render the VNC-Stream
    const vncClient = new VNC(screen, ws, {
      credentials: {
        username: machineName,
        password: 'default', // noVNC needs a password string to trigger the authentication handshake - this password is not used for authentication
        target: machineName,
      },
      shared: true,
    });

    vncClient.viewOnly = true;
    vncClient.resizeSession = true;
    vncClient.background = 'white';

    return {
      vncClient: vncClient,
      ws: ws,
    };
  }
}
