<script lang="ts" setup>
import { computed } from 'vue';
import {
  S2cWidgetCard,
  S2cTemplatePlaceholder,
  useMachineStore,
} from '@multivac-se/s2-components';

import { LoadingStoreContext } from '@/core/utils/LoadingStoreContext';
import { usePlaceholder } from '@/core/utils/usePlaceholder';
import MachinesTable from '@/domain/machines/ui/MachinesTable.vue';

const { machines } = useMachineStore();

const hasNoData = computed(() => !machines.value.length);

const { showPlaceholder, placeholderTextKey } = usePlaceholder(
  LoadingStoreContext.machines,
  hasNoData,
);
</script>

<template>
  <div class="my-4">
    <S2cWidgetCard title="Machines">
      <MachinesTable v-if="!showPlaceholder" />
      <s2c-template-placeholder
        v-else
        :placeholder="$t(placeholderTextKey)"
      />
    </S2cWidgetCard>
  </div>
</template>
