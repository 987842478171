import { scg } from 'ioc-service-container';
import {
  Auth,
  createLogger,
  InstanceIdsCore,
} from '@multivac-se/muwo-frontend-shared';
import { useSwcHeaderFooterUtils } from '@/core/swcHeaderFooter/swcHeaderFooterUtils';
import { App } from 'vue';
import { pushUserAppStartEvent } from '@multivac-se/s2-components';

export async function setupApp(app: App) {
  const logger = createLogger('setupApp');
  const authProvider: Auth = scg(InstanceIdsCore.auth);
  const { authorizationHeader } = useSwcHeaderFooterUtils();

  try {
    const idToken = await authProvider.getIDToken();
    authorizationHeader.value = `Bearer ${idToken}`;
    const router = scg(InstanceIdsCore.router);
    const i18n = scg(InstanceIdsCore.i18n);

    app.use(router);
    app.use(i18n);

    app.mount('#app');

    pushUserAppStartEvent();
  } catch (e: any) {
    logger.error('An error occurred with handling the auth redirect.', e);
    window.location.href = `/error?message=${e.message}`;
  }
}
