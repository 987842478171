import { isDemo } from '@multivac-se/s2-components';
import axios from 'axios';

export function getMachinesVNCViewUrl(machineName: string): string {
  return `${isDemo() ? '/demo/' : '/'}machines/${machineName}`;
}

export function formatError(error: unknown) {
  let msg: string;
  let stack: string | undefined;
  if (axios.isAxiosError(error)) {
    msg = String(error.response?.data.message);
    stack = error.stack;
  } else if (error instanceof Error) {
    msg = error.message;
    stack = error.stack;
  } else {
    msg = String(error);
  }
  return {
    msg,
    stack,
  };
}
