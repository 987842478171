import { VNCConnection } from '@/domain/vnc/models/VNCConnection';
import { InstanceIdVNC } from '@/domain/vnc/registerVNC';
import { scg } from 'ioc-service-container';

export class VNCService {
  createAndAttachVNCConnection(
    screen: HTMLElement,
    machineName: string,
  ): VNCConnection {
    const res = scg(InstanceIdVNC.api).createAndAttachVNCConnection(
      screen,
      machineName,
    );
    return res;
  }
}
